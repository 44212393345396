export const crud = {
    data() {
        return {
            search: null,
            grid: null,
            edit: null,
            detail: null,
            import: null
        };
    },
    mounted() {
        this.initComps();
        this.initEvents();
    },
    methods: {
        initComps() {
            Object.assign(this, this.$refs);
        },
        initEvents() {
            // 查询展开收起
            if (this.search) {
                this.search.$on('onExpand', () => {
                    this.grid && this.grid.adjustSize();
                });
            }

            if (this.grid) {
                // 编辑对话框(创建)
                this.grid.$on('create', () => {
                    this.openCreateDialog();
                });

                // 编辑对话框(修改)
                this.grid.$on('update', record => {
                    this.openUpdateDialog(record);
                });

                // 导入数据
                if (this.import) {
                    this.grid.$on('import', () => {
                        this.openImportDialog();
                    });
                }

                // 导出数据
                this.grid.$on('export', () => {
                    this.exportRecord();
                });

                // 双击快速打开编辑
                if (this.edit) {
                    this.grid.$on('rowDblClick', (record, status) => {
                        if (status === 'update') {
                            this.openUpdateDialog(record[0]);
                        } else if (status === 'iterate') {
                            this.openIterationDialog(record[0]);
                        }
                    });
                }
            }

            // 编辑表单提交
            if (this.edit) {
                this.edit.$on('onSubmit', (params, mode, status, callback) => {
                    if (mode === 'CREATE') {
                        this.createRecord(params, status, callback);
                    } else if (mode === 'UPDATE') {
                        this.updateRecord(params, status, callback);
                    }
                });
            }
        },
        createRecord(params, status, callback) {
            this.grid.createRecord(params, status, callback);
        },
        updateRecord(params, status, callback) {
            this.grid.updateRecord(params, status, callback);
        },
        exportRecord() {
            this.search.$refs.searchForm.validate().then(isValid => {
                if (isValid) {
                    const params = this.search.getParams();
                    this.grid.exportRecord(params);
                } else {
                    this.$Message.error('导出数据查询条件必填项不能为空!');
                }
            });
        },
        doSearch(params) {
            this.$refs.grid.loadData(params);
        },
        openCreateDialog() {
            this.$refs.edit.show('CREATE');
        },
        openUpdateDialog(record) {
            this.$refs.edit.show('UPDATE', record);
        },
        openUpdate2Dialog(record) {
            this.$refs.edit2.show('UPDATE', record);
        },
        openIterationDialog(record) {
            this.$refs.edit3.show('UPDATE', record);
        },
        openSendKnowledgeDialog(record) {
            this.$refs.edit.show('SEND', record);
        },
        openDetailDialog(record) {
            this.$refs.detail.show(record);
        },
        openImportDialog() {
            this.$refs.import.show();
        },
        getSelection() {
            return this.$refs.grid.getSelection();
        },
        reloadRecord() {
            return this.$refs.grid.readRecord();
        }
    }
};

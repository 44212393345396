<template>
    <div>
        <viewport-layout>
            <!-- 查询区域 -->
            <template #search>
                <sv-search @onSearch="doSearch" ref="search" :labelWidth="140" :expand="false">
                    <item label="用户类型" type="combobox" url="" name="A" />
                    <item label="用户名" type="textbox" url="" name="B" />
                    <item label="用户姓名" type="textbox" url="" name="C" />
                    <item label="用户状态" type="combobox" url="" name="D" />
                    <item label="账户有效期" type="combobox" url="" name="E" />
                    <item label="企业编码" type="textbox" url="" name="F" />
                    <item label="企业类型" type="combobox" url="" name="G" />
                    <item label="企业名称" type="textbox" url="" name="H" />
                    <item label="品牌" type="combobox" url="" name="I" />
                    <item label="可选数据库" type="combobox" url="" name="J" />
                </sv-search>
            </template>
            <!-- 列表区域 -->
            <template #grid>
                <sv-grid ref="grid" :rownumber="true" multiSelect @onToolbarClick="handleToolbarClick">
                    <urls>
                        <item name="read" url="/user/read" />
                        <item name="create" url="/standard_labor_name/create" />
                        <item name="update" url="/standard_labor_name/edit" />
                        <item name="delete" url="/standard_labor_name/delete" />
                        <item name="export" url="/standard_labor_name/export" />
                        <item name="stopUse" url="/standard_labor_name/stopUse" />
                        <item name="startUseUrl" url="/standard_labor_name/startUse" />
                        <item name="resetPwd" url="/standard_labor_name/resetPwd" />
                        <item name="addImport" url="/standard_labor_name/addImport" />
                        <item name="updateImport" url="/standard_labor_name/updateImport" />
                    </urls>
                    <toolbars>
                        <item name="create" icon="md-add" text="新增" />
                        <item name="update" icon="ios-create" text="修改" />
                        <item name="stopUse" icon="md-power" text="停用" />
                        <item name="startUse" icon="md-checkmark" text="启用" />
                        <item name="resetPwd" icon="md-refresh" text="重置密码" />
                        <item name="export" icon="md-download" text="导出" />
                        <item name="addImport" icon="md-browsers" text="新增导入" />
                        <item name="updateImport" icon="ios-build" text="更新导入" />
                    </toolbars>
                    <columns>
                        <item title="用户名" key="username" width="90px" />
                        <item title="用户角色" key="role" width="90px" />
                        <item title="用户姓名" key="name" width="90px" />
                        <item title="用户类型" key="type" width="90px" />
                        <item title="企业编码" key="ccode" width="90px" />
                        <item title="企业类型" key="ctype" width="90px" />
                        <item title="企业名称" key="cname" width="90px" />
                        <item title="电话" key="tel" width="90px" />
                        <item title="EMAIL" key="email" width="120px" />
                        <item title="状态" key="status" width="90px" />
                        <item title="到期时间" key="data" dataType="date" width="90px" />
                        <item title="停用理由" key="stopuse" width="90px" />
                        <item title="可选数据库" key="stopuse" width="90px" />
                        <item title="功能权限" key="per" width="90px" />
                        <item title="数据权限包" key="bag" flex="1" />
                    </columns>
                </sv-grid>
            </template>
        </viewport-layout>

        <sv-edit ref="edit" title="工时结构" :labelWidth="118" :width="400">
            <item type="row">
                <item type="col">
                    <item name="username" label="用户名" type="textbox" required="true" />
                    <item name="laborName2" label="用户姓名" type="textbox" required="true" />
                    <item name="laborName3" label="密码" type="textbox" required="true" />
                    <item name="laborName4" label="确认密码" type="textbox" required="true" />
                    <item name="laborName" label="品牌" type="combobox" url="/" required="true" />
                    <item name="laborName5" label="用户类型" type="textbox" required="true" disabled="true" />
                    <item name="laborName5" label="使用截止日期" type="combobox" url="/" required="true" />
                    <item name="laborName5" label="企业" type="combobox" url="/" required="true" />
                    <item name="laborName5" label="企业名称" type="textbox" />
                    <item name="laborName5" label="用户EMAIL" type="textbox" required="true" />
                    <item name="laborName5" label="用户电话" type="textbox" />
                    <item name="laborName5" label="用户角色" type="combobox" url="/" required="true" />
                    <item name="laborName5" label="可选数据库" type="combobox" url="/" required="true" />
                    <item name="id" type="textbox" hidden="true" />
                </item>
            </item>
        </sv-edit>

        <!-- 停用 -->
        <sv-edit ref="edit1" title="用户信息停用" :labelWidth="118" :width="400">
            <item type="row">
                <item type="col">
                    <item name="username" label="用户名" type="textbox" required="true" :maxlength="100" />
                    <item name="id" type="textbox" hidden="true" />
                </item>
            </item>
        </sv-edit>

        <!-- 重置密码 -->
        <sv-edit ref="edit2" title="默认密码" :labelWidth="118" :width="400">
            <item type="row">
                <item type="col">
                    <item name="username" label="密码" type="textbox" required="true" disabled="true" />
                    <item name="id" type="textbox" hidden="true" />
                </item>
            </item>
        </sv-edit>

        <sv-edit ref="edit3" title="测试" :labelWidth="118" :width="700">
            <item type="row">
                <item type="col" span="12">
                    <item name="a" label="编码" type="textbox" />
                    <item name="b" label="姓名" type="textbox" />
                    <item name="c" label="出生年月" type="textbox" />
                    <item name="d" label="地址" type="textbox" />
                    <item name="e" label="部门" type="textbox" />
                    <item name="f" label="地址1" type="textbox" />
                    <item name="g" label="部门2" type="textbox" />
                </item>
                <item type="col" span="12">
                    <item name="image" type="imagefield" />
                </item>
            </item>
        </sv-edit>

        <sv-import ref="import" title="新增导入" :url="url" :downloadTplUrl="downloadTplUrl"></sv-import>
        <sv-import ref="import1" title="更新导入" :url="url1" :downloadTplUrl="downloadTplUrl1"></sv-import>
    </div>
</template>
<script>
import { crud } from '@/mixins/crud';
// import config from 'config';
export default {
    name: 'test',

    mixins: [crud],
    data() {
        return {
            //  baseURL: config.baseURL,
            url: '',
            downloadTplUrl: '',
            url1: '',
            downloadTplUrl1: ''
        };
    },
    mounted() {
        this.$refs.edit1.$on('onSubmit', (params, mode, callback) => {
            if (mode === 'UPDATE') {
                this.grid.updateRecord(params, callback);
            }
        });

        this.$refs.edit2.$on('onSubmit', (params, mode, callback) => {
            if (mode === 'UPDATE') {
                this.grid.updateRecord(params, callback);
            }
        });
    },
    methods: {
        handleToolbarClick(name, record) {

            if (name === 'stopUse') {
                this.$refs.edit1.show('UPDATE', record);
            } else if (name === 'startuse') {
                this.startuse();
            } else if (name === 'resetPwd') {
                this.$refs.edit2.show('UPDATE', record);
            } else if (name === 'addImport') {
                this.$refs.edit3.show();
            } else if (name === 'updateImport') {
                this.$refs.import1.show();
            }
        },
        startuse() { }
    }
};
</script>
<style scoped lang="less">

</style>
